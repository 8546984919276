import React from "react"

const RosaryApostlesCreed = () => {
  return (
    <>
      <h3 className="subheading">The Apostles' Creed</h3>
      <p>
        I believe in God, the Father Almighty, Creator of Heaven and Earth. I
        believe in Jesus Christ, His only begotten Son, our Lord, who was
        conceived by the Holy Spirit, born of the Virgin Mary, suffered under
        Pontius Pilate, was crucified, died and was buried. He descended onto
        hell. On the third day he rose again from the dead. He ascended into
        heaven and is seated at the right hand of the Father. He will come again
        to judge the living and the dead. I believe in the Holy Spirit, the Holy
        Catholic Church, the communion of saints, the forgiveness of sins, the
        resurrection of the body, and life everlasting. AMEN.
      </p>
    </>
  )
}

export default RosaryApostlesCreed
