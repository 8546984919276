import React from "react"
import OpeningPrayersData from "../../data/novena/shared/opening-prayers.json"
import DialogContainer from "./dialog-container"
import OpeningPrayersPraises from "./opening-prayers-praises"

const OpeningPrayers = () => {
  return (
    <div className="opening-prayers-container">
      <h2 className="heading">Opening Prayers</h2>

      <DialogContainer data={OpeningPrayersData} />

      <OpeningPrayersPraises />
    </div>
  )
}

export default OpeningPrayers
