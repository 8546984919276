import React from "react"

const FinalSong = () => {
  return (
    <div className="final-song">
      <h2 className="heading">Final Song -- Hymn to our Lady of Fatima</h2>

      <p>
        Our Lady of Fatima
        <br />
        We come on bended knees
        <br />
        To beg your intercession
        <br />
        For peace and unity
      </p>

      <p>
        Dear lady, won't you show us
        <br />
        The right and shining way
        <br />
        We pledge our love and offer you
        <br />A rosary each day
      </p>

      <p>
        You promised at Fatima
        <br />
        Each time that you appeared
        <br />
        To help us if we pray to you
        <br />
        To vanish war and fear
      </p>

      <p>
        Dear Lady on first Saturdays
        <br />
        We ask your guiding hands
        <br />
        For grace and guidance here on earth
        <br />
        And protection of our land
      </p>

      <p>
        <strong>¡VIVA SANTISIMA TRINIDAD!</strong>
      </p>
    </div>
  )
}

export default FinalSong
