import React from "react"
import PostReflectionPrayersPetition from "./post-reflection-prayers-petitions"
import PostReflectionPrayersConsecration from "./post-reflection-prayers-consecration"
import PostReflectionPrayersConcludingPrayers from "./post-reflection-prayers-concluding-prayers"

const PostReflectionPrayers = () => {
  return (
    <>
      <h2 className="heading">Novena Prayers (cont)</h2>

      <PostReflectionPrayersPetition />
      <PostReflectionPrayersConsecration />
      <PostReflectionPrayersConcludingPrayers />
    </>
  )
}

export default PostReflectionPrayers
