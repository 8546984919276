import React from "react"
import reflectionTitles from "../../../data/novena/reflections/titles.json"
import ReflectionPrayer from "../reflection-prayer"

const ReflectionDay7 = ({ day }) => {
  return (
    <>
      <h3 className="subheading">{reflectionTitles[day]}</h3>

      <p>
        Leader: On this seventh day of our Novena Prayers, we will focus our
        reflection on our BLESSED MOTHER MARY, mother of Jesus. Her feast day is
        January 1st. She is the Patroness of all HUMANITY. Her birthday is
        September 8th which is called the FEAST OF THE NATIVITY OF MARY and her
        death Anniversary is August 15th celebrated as her DAY OF ASSUMPTION to
        heaven. She is known as Mary, also known as St. Mary the Virgin, the
        Blessed Virgin Mary, and Mary Mother of God. The Blessed Virgin Mary is
        the greatest of all Christian saints. The Virgin Mother "was after her
        Son, exalted by divine grace above all angels and men."
      </p>

      <p>
        Our intention in bringing Mama Mary in our reflection in this Novena
        Prayers to the Most Blessed Trinity is because she played a MAJOR ROLE
        IN THE HISTORY OF OUR SALVATION. But in meditating on her, we must
        accept these basic truths about her. IMMACULATE CONCEPTION. Although the
        belief that Mary was sinless and conceived immaculate has been widely
        held since early Christianity, the doctrine was proclaimed as a dogma in
        1854 by Pope Pius IX. The Catholic Church celebrates the Solemnity of
        the Immaculate Conception on December 8th. The dogma of the Immaculate
        Conception states "that the Blessed Virgin Mary, from the first moment
        of her conception, by a singular grace and privilege from Almighty God
        and in view of the merits of Jesus Christ, was kept free of every stain
        of original sin." Original sin provokes disorderliness in thought and
        behavior, especially with regard to the primacy of God’s presence in our
        life.
      </p>

      <p>
        Consequently, in declaring Mary as immaculately conceived, the Church
        sees in Mary as one who never denied God the least sign of love. Thus,
        the dogma declares that from the beginning Mary was exceptionally holy
        and in constant union with the sanctifying grace of the Holy Spirit.
      </p>

      <p>THE ASSUMPTION</p>

      <p>
        This Marian dogma was proclaimed by Pope Pius XII on November 1, 1950.
        Here a distinction needs to be made between Ascension and Assumption.
        Jesus Christ, Son of God and the risen Lord, ascended into heaven is a
        sign of divine power. Mary, on the other hand was elevated or assumed
        into heaven by the power and grace of God.
      </p>

      <p>
        The dogma states that "Mary, Immaculate Mother of God, and ever Virgin,
        after finishing the course of her life on earth, was taken up body and
        soul to heavenly glory." This definition as well as that of the
        Immaculate Conception makes not only reference to the universal belief
        and firm consent of the Church’s Magisterium but also makes allusion to
        the concordant belief of the people. The Assumption had been a part of
        the Church’s spiritual and doctrinal patrimony for centuries. It had NOT
        ONLY been part of a theological reflection but also of the liturgy and
        of the common belief of the faithful.
      </p>

      <p>
        The definition of the dogma does not say how the transition from Mary’s
        earthly state to her heavenly state happened. Did Mary die? Was she
        assumed to without prior separation of soul and body? However, the
        opinion that Mary passed through death as her Son did, has the stronger
        support in tradition.
      </p>

      <p>
        If Jesus is the Mediator between man and God, our Blessed Mother Mary is
        her Co-Mediator. She can aptly be called Mediatrix. The title of Mary as
        co-redemptrix has been used by many Catholic saints since the 14th
        century, including St. Bridget of Sweden, St. Veronica Giuliani and St.
        Maximillian Kolbe. In 1914, the Holy Office – now called the
        Congregation for the Doctrine of the Faith under St. Pius X, approved an
        indulgence for a prayer invoking Mary as "co-redemptrix of the human
        race." Pop Pius XI referred to Mary as co-redemptrix three times in
        public addresses during his pontificate, and Pope St. John Paul II used
        the term or its cognate at least six times while pope. He said: "In
        union with Christ and in submission to Him she cooperated with her Son
        and His salvific mission."
      </p>

      <p>
        Mary as co-redemptrix basically means that, by the will of God, Mary
        cooperated in a unique and singular manner in the work of redemption,
        with and under her divine Son. Mary, by her obedience to God’s plan,
        "became the cause of salvation for herself and for the whole human race"
        (Lumen Gentium 56). Moreover, at the foot of the cross, suffering in a
        profound way with her only begotten Son, Mary "uniting herself with a
        maternal heart with his sacrifice," and lovingly consented "to the
        immolation of this victim which she herself had brought forth" (Lumen
        Gentium 58).
      </p>

      <p>
        The Blessed Mother of Jesus deserves to be venerated by all Christians.
        One prayer of veneration that the Church highly recommends is the Holy
        Rosary. Pope John Paul II in his Apostolic Letter on October 12, 2002
        says: "The Rosary, though clearly Marian in character, is at heart a
        Christocentric prayer. Through the Rosary, the faithful receive abundant
        grace, from the very hands of the Mother of the Redeemer."
      </p>

      <p>
        Glorified in body and soul, BLESSED MOTHER MARY is already in the state
        that will be ours after the resurrection of the dead. No wonder, Mama
        Mary appeared to many in person in many and in different places around
        the globe.
      </p>

      <ReflectionPrayer day={day} />
    </>
  )
}

export default ReflectionDay7
