import React from "react"
import ReflectionPrayer from "../reflection-prayer"
import reflectionTitles from "../../../data/novena/reflections/titles.json"

const ReflectionDay9 = ({ day }) => {
  return (
    <>
      <h3 className="subheading">{reflectionTitles[day]}</h3>

      <p>
        Leader: On 1st day of our Novena to the Most Blessed Trinity, we have
        meditated on the Mystery of the BLESSED TRINITY.
      </p>

      <p>
        On the 2nd day of our meditation, we reflected on God the Father,
        Creator of everything seen and unseen. On the 5th day, we meditated on
        the coming of Christ. And on the 6th day, the focus of our reflection
        was about the Holy Spirit, the third Person in the Trinity?
      </p>

      <p>
        On this 9th day of our Novena to the Most Blessed Trinity, we have one
        very simple question: WHY IS JESUS CHRIST the center of our faith in the
        Church?
      </p>

      <p>
        Most people base their FAITH on what the senses can perceive. Two
        thousand years ago, a child was born, worshipped and adored by three
        kings. However, another king by the name of Herod sought the death of
        this child and the scheme of his criminal mind caused the death of
        hundreds of innocent children at that time.
      </p>

      <p>
        During his childhood days, Christ was virtually an unknown, except that
        he was son of a carpenter named Joseph who lived in Nazareth with his
        wife named Mary. Surprisingly, His name was mentioned again when he was
        found in the Temple talking to the doctors and experts of the Laws in
        Jerusalem. He was twelve years old then (Lk. 2:48-50). After this
        incident nothing was heard of this man, named Christ.
      </p>

      <p>
        The next time that people had met Him was when he was in the river
        Jordan. This time he intentionally came down to the river Jordan and
        submitted himself to be baptized by St. John the Baptist, his own
        cousin. After his baptism he travelled from one place to another
        preaching the GOOD NEWS and the KINGDOM OF GOD. He gained the trust of
        many. He chose twelve special men from different talks of life and
        called them his Apostles. They were his closest friends and
        collaborators. All the rest who had followed him were called his
        disciples.
      </p>

      <p>
        The learned and experts of the Law hated him because he was a threat to
        their organization when he openly declared: "Do not think that I have
        come to abolish the law or the prophets, I have come not to abolish but
        to fulfill." (Mt. 5; 17). Secretly, the elders wanted him dead but they
        had no proof or evidence in seeking for his death. And when the elders
        of the Council once again met, in front of Jesus, they all said: "Are
        you the son of God?" He answered, "You say that I am" (Lk 22:66-70).
        This was blasphemy according to Jewish Law punishable by death.
      </p>

      <p>
        The Council of Elders did not have authority to impose the death
        penalty, so the whole multitude of them arose, and led him unto Pilate.
        And they began to accuse him, saying, "We found this [fellow] perverting
        our nation, forbidding us to pay taxes to the Emperor and saying that he
        is the messiah, a king". Then Pilate asked him: "Are you the king of the
        Jews?" And he answered, "You say I am". So He was sentenced to die on
        the Cross, and on the third day He rose from the dead. Across history
        until today, Christ's life story reverberated across the four corners of
        the world. Many has continued to ask WHO THIS MAN JESUS REALLY was.
        Theologians, Archeologists, and Biblical Scholars have investigated
        almost everything about this Man who is at the same time also a God.
      </p>

      <p>
        And JESUS CHRIST, the man who delivered us from the slavery of sin lived
        in this world. He was seen and touched by many. This left all to imagine
        in awe and wonder, who really is this man, the so-called son of a
        carpenter in Galilee. And because history judges man from this actions
        and deeds, let us now relive the Life of Christ, the man, to the open.
      </p>

      <p>
        In the field of Biology, He was born without the normal process of
        conception because He was conceived by the Power of the Holy Spirit. In
        the field of Chemistry, He turned after into a first class wine without
        adding any ingredients. In the field of Economics, He disapproved the
        Law of Diminishing Return by feeding 5,000 men and women with only two
        fishes and five loaves of bread. In the field of Medicine, He cured the
        sick and restore sight to the blind without administering any clinical
        procedure or any single dose of a drug.
      </p>

      <p>
        In the field of Thanatology (Science on Death), He gave back the life of
        Lazarus who had been in his tomb for four days. In Government, He said
        He has no Kingdom of this world but He is called the Prince of Peace. In
        Physics, He defied the Law of Gravity when He ascended into Heaven. In
        the field history, He is the beginning and the end. And in Religion, He
        said, "I am the way, the Life, and the Truth. No one can come to the
        Father except through me."
      </p>

      <p>
        What are the achievements of Jesus? Let us bow our heads and in silence
        acknowledge the follow:
      </p>

      <p>
        Jesus has no servants yet He is called Master. Jesus had no degree, but
        He is called Teacher. Jesus is not a doctor yet He is called the Healer.
        Jesus had no army but He was feared by kings. Jesus won no battles, yet
        He had conquered the world. Jesus committed no crime, yet He was
        sentenced to death. Finally, Jesus was buried in a tomb, yet HE IS STILL
        ALIVE AMONGST US, today and forever.
      </p>

      <ReflectionPrayer day={day} />
    </>
  )
}

export default ReflectionDay9
