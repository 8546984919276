import React from "react"
import FinalSong from "./final-song"
import Litany from "./litany"
import OpeningPrayers from "./opening-prayers"
import Rosary from "./rosary"
import PostReflectionPrayers from "./post-reflection-prayers"
import Reflection from "./reflection"
import reflectionTitles from "../../data/novena/reflections/titles.json"

const NovenaPrayerFull = ({ day }) => {
  return (
    <>
      <h1>{reflectionTitles[day]}</h1>
      <br />
      <Rosary />
      <Litany />
      <OpeningPrayers />
      <Reflection day={day} />
      <PostReflectionPrayers />
      <FinalSong />
    </>
  )
}

export default NovenaPrayerFull
