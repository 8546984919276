import React from "react"
import PostReflectionPrayersConcludingPrayersPartOneData from "../../data/novena/shared/post-reflection-prayers-concluding-prayers-1.json"
import PostReflectionPrayersConcludingPrayersPartTwoData from "../../data/novena/shared/post-reflection-prayers-concluding-prayers-2.json"
import DialogContainer from "./dialog-container"
import OurFatherDialogButton from "./dialog-boxes/our-father-dialog-button"
import HailMaryDialogButton from "./dialog-boxes/hail-mary-dialog-button"
import GloryBeDialogButton from "./dialog-boxes/glory-be-dialog-button"

const PostReflectionPrayersConcludingPrayers = () => {
  return (
    <>
      <h3 className="subheading">Concluding Prayers</h3>
      <DialogContainer
        data={PostReflectionPrayersConcludingPrayersPartOneData}
      />

      <br />
      <div className="highlight">
        Pray one <OurFatherDialogButton />, one <HailMaryDialogButton />, and
        one <GloryBeDialogButton />
      </div>
      <br />

      <p>
        <strong>Final Prayer</strong>
      </p>
      <DialogContainer
        data={PostReflectionPrayersConcludingPrayersPartTwoData}
      />
    </>
  )
}

export default PostReflectionPrayersConcludingPrayers
