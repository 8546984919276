import React from "react"

const Reflection = ({ day }) => {
  let reflectionContent = null

  try {
    reflectionContent = require(`./reflections/day-${day}`)
  } catch (err) {}

  return (
    <>
      <h2 className="heading">Reflection</h2>
      <div className="highlight">
        Let no one forget that Prayer is a heart talk with God and Reflections
        are mental conversations with the Holy Spirit. (IMTBA)
      </div>

      {reflectionContent !== null ? (
        reflectionContent.default({ day })
      ) : (
        <h3>No reflection data found for day {day}.</h3>
      )}
    </>
  )
}

export default Reflection
