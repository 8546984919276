import React from "react"
import RosaryApostlesCreed from "./rosary-apostles-creed"
import RosaryDoxology from "./rosary-doxology"
import RosaryHailHolyQueen from "./rosary-hail-holy-queen"
import RosaryMysteries from "./rosary-mysteries"

const Rosary = () => {
  return (
    <div className="rosary-container">
      <h2 className="heading">The Rosary</h2>

      <RosaryDoxology />
      <RosaryApostlesCreed />
      <RosaryMysteries />
      <RosaryHailHolyQueen />
    </div>
  )
}

export default Rosary
