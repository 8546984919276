import React from "react"
import ReflectionPrayer from "../reflection-prayer"
import reflectionTitles from "../../../data/novena/reflections/titles.json"

const ReflectionDay4 = ({ day }) => {
  return (
    <>
      <h3 className="subheading">{reflectionTitles[day]}</h3>

      <p>
        Leader: The "five days" of creation showed GOD'S WISDOM for creating the
        less perfect to the more perfect when HE created man on his sixth day of
        work. "God created man in his own image, in the image of God he created
        him, male and female he created them" (Gen 11:27).
      </p>

      <p>
        MAN - THE PERSON. Being in God's image makes the individual man and
        woman, a PERSON, a "someone" and not a "something". He is capable of
        self-knowledge, knownledge of the things around him and of having
        friendships with other persons. He is also capable of loving. He is
        called into a Covenant with God and can make a response of love not
        known by any other creature.
      </p>

      <p>
        In material creation only man can know and love God. He was willed by
        God for his own sake and he is called to share in God's own life. St.
        Caterine of Siena asked, "What made God establish man in so great a
        dignity?"
      </p>

      <p>
        MAN IS COMPOSED OF BODY AND SOUL. Using symbolic language, the Bible
        says that God willed man to be both corporeal and spiritual. "Then the
        Lord God formed man of fust from the ground, and breathed into his
        nostrils the breath of life and man became a living being" (Gen. 2:7).
        In Scripture "soul" can mean human life or the entire human person. It
        is the spiritual rinciple within man.
      </p>

      <p>
        The spiritual soul is the "form" of the body. Man is not the union of
        two natures. I man, spirit and matter form one nature. The only
        difference is that when man is devoid of life, when the soul separates
        from the body during death, the body rots and returns to dust. The soul
        lives into eternity because it is Immortal.
      </p>

      <p>
        God creates every spiritual should immediately upon conception. It is
        not "produced" by the parents and does not perish at death. It is
        reunited with the body at the final resurrection.
      </p>

      <p>
        GOD MADE MAN, MALE AND FEMALE, HE MADE THEM. Becauseman was alone, God
        created a "helper fit for him" (Gen. 2:19-20). Woman is mde as a
        COMPANION, NOT A SLAVE. When God fashioned the woman, the man cried out
        in wonder, "This, at last, is bone of my bones and flesh of my flesh."
        (Gen. 2:23). By this, Adam knowledges that woman shares his humanity.
      </p>

      <p>
        God willed that man and woman would have perfect equality as human
        persons. "Being man" or "being woman" is a great reality and each
        possesses an inalienable dignity. In contrast, God is neither man nor
        woman. He is pure spirit. The different perfections of man and woman (as
        mother, father, husband, wife) reflect and speak of God's infinite
        perfections.
      </p>

      <p>
        MAN AND WOMAN ENJOYS TO FRIENDSHIP OF GOD. Man was created in friendship
        with his Creator, in harmony with himself and with all creation, and in
        a state surpassed only by the new creation in Christ.
      </p>

      <p>
        Our first parents, Adam and Eve, enjoyed an original "sate of holiness
        and justice" by which they shared in divine life. While remaining in
        this divine intimacy, they would not suffer or die. They also possessed
        a personal inner harmony and a harmony with each other, and with
        creation. In other words, they enjoyed a Person-to-Person Relationship
        with God. This was called "original justice".
      </p>

      <p>
        This divine gift of original "state of holiness and justice" was
        realized within man himself because he was free from the triple
        concupiscence that now subjects all men. These are sensual pleasure,
        covetousness of earthly good, and false self-assertion. These three
        evils are sensual lust, enticement for the eyes and a pretentious life.
        In the garden of Paradise, work was not a burden because man was always
        in harmony with the Will of God. Unforunately, our first parents lost
        this entire harmony by their sin.
      </p>

      <p>
        Now, man can rightly ask. Where does evil come from? "I seek to know
        where evil comes from and there was no solution." says St. Augustine.
      </p>

      <p>
        This is because evil or sin comes from within man. Committing a sin is
        man's assertion of his self-pride, self-worth, not remembering that he
        is only a creature of God. And when we commit a MORTAL SIN we reject the
        Image of God in us rationally and functionally. That is why we are
        always disturbed when God is NO LONGER within us. Remember man's life is
        NOT for the joy and happiness of this world but for the world to come,
        and that is ETERNAL LIFE and HAPPINESS. Life with and towards God must
        be man's direction here on earth.
      </p>

      <ReflectionPrayer day={day} />
    </>
  )
}

export default ReflectionDay4
