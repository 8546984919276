import React from "react"
import PostReflectionPrayersConsecrationData from "../../data/novena/shared/post-reflection-prayers-consecration.json"
import DialogContainer from "./dialog-container"

const PostReflectionPrayersConsecration = () => {
  return (
    <>
      <h3 className="subheading">Consecration to the Three Divine Persons</h3>
      <DialogContainer data={PostReflectionPrayersConsecrationData} />
    </>
  )
}

export default PostReflectionPrayersConsecration
