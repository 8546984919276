import React from "react"
import LitanyDialog from "../../data/novena/shared/litany-dialog.json"
import LitanySayingsData from "../../data/novena/shared/litany-sayings.json"
import DialogContainer from "./dialog-container"
import Divider from "./divider"
import LitanyEntranceSong from "./litany-entrance-song"
import LitanyActOfContrition from "./litany-act-of-contrition"
import LitanySalutations from "./litany-salutions"

const Litany = () => {
  return (
    <div className="litany-container">
      <h2 className="heading">The Litany of the Blessed Virgin Mary</h2>

      <DialogContainer
        data={LitanySayingsData}
        className="litany-response-container"
      />
      <Divider />
      <DialogContainer data={LitanyDialog} />
      <LitanyEntranceSong />
      <LitanyActOfContrition />
      <LitanySalutations />
    </div>
  )
}

export default Litany
