import React from "react"
import LitanySalutationsData from "../../data/novena/shared/litany-salutations.json"
import DialogContainer from "./dialog-container"

const LitanySalutations = () => {
  return (
    <div className="litany-salutations-container">
      <h3 className="subheading">Salutations</h3>
      <DialogContainer data={LitanySalutationsData} />
    </div>
  )
}

export default LitanySalutations
