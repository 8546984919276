import React from "react"
import reflectionTitles from "../../../data/novena/reflections/titles.json"
import ReflectionPrayer from "../reflection-prayer"

const ReflectionDay8 = ({ day }) => {
  return (
    <>
      <h3 className="subheading">{reflectionTitles[day]}</h3>

      <p>
        Leader: The Second Vatican Council asserts that "Christ is the light of
        the nations and with his proclamation of the GOOD NEWS in the Gospels,
        it is hoped that this light will shine out visibly from the Church";
        just as the moon has the light from the sun, the Church has the light
        from Christ. The Church depends totally on the Holy Spirit, because the
        Spirit "has endowed the Church with holiness." Belief that the Church is
        One, Holy, Catholic and Apostolic is inseparable from the belief in the
        Blessed Trinity. We do not believe in the church but in God who bestowed
        his gifts on the Church.
      </p>

      <p>
        The Salvific Mission of Christ would have failed had he not established
        a church. This is within the divine plan of God for the second person to
        die on the cross to save mankind from sin.
      </p>

      <p>
        The Church is born from Christ’s total self-giving. The blood and water
        flowing from Christ’s side symbolize the origin and the growth of the
        Church. Just as Eve came from the side of Adam, so the Church came from
        the side of Christ. This Church has seven (7) distinguishing marks
        distinct and different from any or every group in history. These marks
        are:
      </p>

      <ol>
        <li>
          The people of God are not the property of any one people, race,
          nationality or group. Membership is based on FAITH in Christ and
          Baptism, not from physical birth.
        </li>
        <li>
          The People are messianic; Christ shares with his anointing of the
          Spirit.
        </li>
        <li>
          Their dignity is to be children of God because God’s Spirit dwells
          within them.
        </li>
        <li>Their law is to love other as Christ loved them (John 13:34)</li>
        <li>
          Their Mission is to be the "light of the world" and the "salt of the
          earth", a hope for the whole human race.
        </li>
        <li>Their destiny is the Kingdom of God, in time and eternity.</li>
      </ol>

      <p>
        Within this Church, there is Unity. This unity stimulates charity.
        "There is neither Jew nor Greek slave or free, male or female. All is
        one in Christ Jesus" (Catholic Catechism 790-791). Besides Charity, this
        Church has three visible bonds of common union: PROFESSION OF ONE FAITH,
        COMMON CELEBRATION OF WORSHIP, and APOSTOLIC SUCCESSION (Catholic
        Catechism 815)
      </p>

      <p>
        In this Church, Christ gives his own life to believers through the
        sacraments. There are seven (7) SACRAMENTS. These sacraments serve as
        bridges from earth to the Heavenly Kingdom of God.
      </p>

      <p>
        By BAPTISM a person is born again and once more the gates of God’s
        kingdom are opened to him. And this person shares in the mission of
        Christ. By CONFIRMATION, a Christian is given the SEAL of the Holy
        Spirit so everyone would know that he belongs to the fold of Christ as
        the Shepherd. And because there is a flock to be attended to, Christ
        established the sacrament of HOLY ORDERS. Priests take charge of the
        Ministerial authority and work of the Church.
      </p>

      <p>
        Sin is primarily an offense against God, and it also damages the common
        union of the members in the Church. Reconciliation with God and with the
        members must be restored. This is achieved by the SACRAMENT OF PENANCE.
      </p>

      <p>
        Scripture speaks extensively of MARRIAGE, its mystery, its origin and
        its purpose. It describes the difficulties in marriage caused by sin and
        the renewal of marriage by Christ. The union of man and woman was from
        the beginning of creation blessed by God. This same union was made more
        meaningful in the Wedding Feast at Cana. This definitely shows that the
        SACRAMENT OF MARRIAGE can be bridge for families, husband and wife,
        father and mother and children to the kingdom of God.
      </p>

      <p>
        St. James attests to the early Church’s own rite for the sick. The
        elders (presbyters) should pray over the sick person and anoint him with
        oil in the Lord’s name. The Lord will raise him up and forgive his sins
        (James 5:14-15). Tradition has recognized this rite as one of the seven
        sacraments, the ANOINTING OF THE SICK (used to be called EXTREME
        UNCTION).
      </p>

      <p>
        All the Church’s Sacraments and ministries are oriented to the HOLY
        EUCHARIST, "the source and summit of Christian life". In the Eucharist,
        the whole spiritual good of the Church (Christ himself) is contained. In
        the Eucharist we are united with the heavenly liturgy and anticipate
        eternal life with God.
      </p>

      <p>
        The bread and wine become Christ’s Body and Blood by the words of Christ
        and the invocation of the Holy Spirit. One the eve of his Passion, "he
        took bread" and "he took the cup filled with wine." The bread and wine
        become the Body and Blood of Christ. The bread becomes the REAL BODY of
        Christ and the wine becomes the REAL BLOOD of Christ. This miracle
        happens during Consecration and in every Consecration during the Holy
        Mass. The substance of the bread and the substance of the wine through
        the process of TRANSUBSTANTIATION.
      </p>

      <ReflectionPrayer day={day} />
    </>
  )
}

export default ReflectionDay8
