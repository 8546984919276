import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NovenaPrayerFull from "../components/novena-prayers/novena-prayer-full"
import ReflectionsMenu from "../components/novena-prayers/reflections-menu"
import reflectionTitles from "../data/novena/reflections/titles.json"

const NovenaPrayer = ({ pageContext }) => {
  const day = pageContext.day
  return (
    <Layout>
      <SEO title={`${reflectionTitles[day]} - Novena Prayers`} />
      <ReflectionsMenu day={day} />
      <NovenaPrayerFull day={day} />
    </Layout>
  )
}

export default NovenaPrayer
