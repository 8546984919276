import React from "react"
import ReflectionPrayer from "../reflection-prayer"
import reflectionTitles from "../../../data/novena/reflections/titles.json"

const ReflectionDay5 = ({ day }) => {
  return (
    <>
      <h3 className="subheading">{reflectionTitles[day]}</h3>

      <p>
        Leader: This is the history of man's relationship with God. Man and GOD
        had a personal relationship after all creation was done. Man talked to
        God and God listened to Adam and Eve. Man accepted freely this
        friendship with God. His command to Adam (not to eat of the fruit of the
        tree of knowledge of good and evil) shows symbolically that man had to
        acknowledge his limits as a creature and submit his behavior to God.
      </p>

      <p>
        Although using figurative language and beyond recorded history, the
        story of Adam's sin does describe our wounded state of life and the need
        for healing. All human history is marked by that fall of our first
        parents. By the first sin of Adam, man set aside his trust in God and
        disobeyed Him. All subsequent sins would be disobedience and lack of
        trust. By sin, man rebelled against his status as a creature. Man wanted
        to be of the same status as his God. Man's choice was a bad one because
        he wanted to be like God.
      </p>

      <p>
        Our first parents lost original holiness and because afraid of God, whom
        they falsely thought was jealous of their prerogatives, The soul's
        control over the body's powers was shattered. The union of man with
        woman became subject to tensions and their sexual relationship was
        marked by lust and domination (Gen 3:5-16). Creation became hostile to
        man and death made its entrance into human history. "Sin entered the
        world and through sin, DEATH..." (Rom. 5:12).
      </p>

      <p>
        But from the benevolence of a GOD flows HIS MERCY. From the mercy of a
        God springs His forgiveness. Forgiveness means reconciliation. The first
        man and woman had offended God by disobeying His command. In fact, God
        had banished them from that beautiful Garden of Eden and punished them
        so badly. The greatest punishment was this: Man could no longer talk to
        God in a "person-to-person" style the way Adam and Eve did, before
        disobeying God.
      </p>

      <p>
        But as always in God's Divine Plan, He wanted man to share in His
        blessings. God always comes close to man and calls man to seek Him.
      </p>

      <p>
        The disobedience of Adam and Eve only meant one thing: A REJECTION OF
        GOD. This was an INFINITE OFFENSE because God is INFINITE. NO AMOUNT OF
        MERIT by any human sacrfice can appease, satisfy and pay for this
        offense.
      </p>

      <p>
        After the original sin, God still called man and even promised a
        victory. He announced that there would be a Messiah and Redeemer, a
        battle between the serpent and the Woman, and the final victory for her
        descendants: "I will put an enmity between you and the woman and between
        your offspring and hers. He will strike at your head while you strike at
        his heel" (Gen. 3:15). We call this the "proto evangelium" or the "first
        Gospel" because it preannounced the coming of Jesus our Savior, Son of
        Mary.
      </p>

      <p>
        This was an announcement of the coming of Christ, the INFINITE
        sacrificial LAMB, Christ came to this world in the cloak of a man and
        yet retaining his being a God. The fact is Christ in himself has two
        natures: human and divine. He is God and man at the same time.
      </p>

      <p>
        This assertion had caused and generated so many questions and skepticism
        among people, even until now. How can divinity and humanity exist in
        Christ at the same time? The answer to this question is clearly
        explained in the Epistle of St. Paul to the Philippians (Ph. 2:7). In
        order to become man and at the same time still remaining as God, Christ
        EMPTIED himself of his eternal and divine happiness, thereby taking into
        himself sadness and even death; then he said to them, "My soul is
        sorrowful even unto death". (Mt. 26:38).
      </p>

      <p>
        The MERIT of Christ's sacrifice in becoming a MAN and DYING ON THE CROSS
        had an INFINITE MERIT. This is because Christ is also God, the Second
        Person in the Trinity. This is called SALVATION.
      </p>

      <p>
        NO GREATER GIFT to man did GOD THE FATHER GIVE than sending His Beloved
        Son to act as the divine bridge in man's relationship with Him. In
        JESUS, God the Father personally talked to man again. And Christ had a
        person to person relationship with men (His apostles, disciples and
        followers) because He is also human.
      </p>

      <p>
        Leo the Great once said, "Christ gave us better blessings than the devil
        took away." And St. Thomas Aquinas wrote that "There is nothing to
        prevent human nature from being raised up to something greater, even
        after sin.". And as we sing in the Exultet, that beautiful humn sung at
        the beginning of the Easter Vigil, "O happy fault...which gained for us
        so great a Redeemer.". Let us thank Christ Jesus for restoring back to
        us that important relationship and trust with God, the Father.
      </p>

      <ReflectionPrayer day={day} />
    </>
  )
}

export default ReflectionDay5
