import React from "react"
import ReflectionPrayer from "../reflection-prayer"
import reflectionTitles from "../../../data/novena/reflections/titles.json"

const ReflectionDay3 = ({ day }) => {
  return (
    <>
      <h3 className="subheading">{reflectionTitles[day]}</h3>

      <p>
        Leader: (Genesis 1:1-2:31) "In the beginning, God created the heavens
        and the earth. The earth was without form and void, and darkness was
        over the face of the deep. And the Spirit of God was hovering over the
        face of the waters."
      </p>

      <p>
        And God said, "Let there be light," and there was light. And God saw
        that the light was good. Then God separated the light from the darkness.
        God called the light, Day, and the darkness He called night."
      </p>

      <p>
        And God said, "Let there be an expanse in the midst of the waters, and
        let it separate from the waters...". "And God called the expanse
        Heaven."
      </p>

      <p>
        And God said, "Let the earth sprout vegetation, plants yielding seeds,
        and fruit trees bearing fruits, each according to its kind, on the
        earth." And it was so. The earth brought forth vegetation, plans
        yielding seed according to their own kind, and trees bearing fruit in
        which is their seed, each according to its kind. And God saw that it was
        good.
      </p>

      <p>
        And God said, "Let there be light in the expanse of the heavens to
        separate the day from the night. And let them be for signs and for
        seasons, and for days and years, and let their lights in the expanse of
        the heavens give light upon the earth. And it was so. And God made the
        two great lights; the greater light to rule the day and the lesser light
        to rule the night and the stars..."
      </p>

      <p>
        And God said, "Let the waters swarm with living creatures, and let the
        birds fly above the earth across the expanse of the heavens..."
      </p>

      <p>
        And God said, "Let the earth bring forth living creatures according to
        their kinds livestock and creeping things and beasts of the earth
        according to their kinds." And it was so...
      </p>

      <p>
        THE MESSAGE OF CREATION is rather a long, long story. It took God six
        days to do what HE had in His supreme mind and power. But there is one
        great thing that we must have to reflect, and reflect we must seriously
        about:
      </p>

      <p>THE EARTH WE LIVE IN.</p>

      <p>
        God created everything for man. According to St. John Chrysostom, man is
        more precious in God’s eyes than all other creatures. The heavens and
        the earth exist for him. The earth we live in is so far the only planet
        that has maintained and sustained LIFE.
      </p>

      <p>
        Man is the caretaker of the earth. He lives in it WITHOUT RENT. He makes
        use of the earth’s resources for FREE. The air we breathe, the rain we
        bathe, the soil we till for products we eat. All these and everything we
        have like the natural resources, the views we see, the beauty of
        mountains, and the splendor of the oceans and seas, all these are given
        FREE.
      </p>

      <p>
        But does man keep and maintain the beauty, the healthiness of the earth
        as created by God?
      </p>

      <p>
        This is what God wants: a healthy earth, a healthy ecological
        environment that maintains good life on the face of the earth and the
        space above her.
      </p>

      <p>
        NOW, at present, what was once the beautiful masterpiece of a living God
        is being slowly destroyed by man. The earth today is being shaped by us
        to become a wasteland in the years to come. This is not what God has in
        His mind at the start of creation.
      </p>

      <p>
        And very soon, we will encounter God's anger like the old days of Noah,
        Sodom and Gomorrah.
      </p>

      <p>
        As a member of the "people of God", it is our duty to maintain and take
        care of God's things. If we do not know how to keep and take care of
        God's things on earth, God will slowly take these back from us.
      </p>

      <p>
        And GOD is just starting to do it. Because of the destruction of the
        environment by man, there is flooding, drought; all sorts of calamity
        that makes man suffer.
      </p>

      <p>
        So, what must each one of us do to restore God's creation? Right now, it
        is imperative that each one of us must do even just a little to help
        restore the earth from becoming a wasteland. This is the only one planet
        we have so far where life exists. If we destroy this planet, where will
        the next generations live?
      </p>

      <ReflectionPrayer day={day} />
    </>
  )
}

export default ReflectionDay3
