import React from "react"
import reflectionTitles from "../../../data/novena/reflections/titles.json"
import ReflectionPrayer from "../reflection-prayer"

const ReflectionDay6 = ({ day }) => {
  return (
    <>
      <h3 className="subheading">{reflectionTitles[day]}</h3>

      <p>
        Leader: Except that the Holy Spirit is invoked together with God the
        Father and God the Son in the Doxology and in the Apostles’ Creed, the
        Holy Spirit is Seldom mentioned by Biblical Scholars and Theologians in
        their lectures and studies on Christianity. This will lead us to think
        that the HOLY SPIRIT IS ALMOST A FORGOTTEN PERSON in the Blessed
        Trinity.
      </p>

      <p>WHO then is the HOLY SPIRIT?</p>

      <p>
        The Holy Spirit is one of the PERSONS of the Blessed Trinity,
        consubstantial with the Father and the Son (Catechism of the Catholic
        Church Chap 3 #685). The Holy Spirit is the Paraclete, the third Person
        of the Holy Trinity. We refer to God the Father as Creator, God the Son
        as Redeemer, and God the Holy Spirit as Sanctifier. He is called the
        Sanctifier because the essence of sanctity is LOVE and He is the
        PERSONIFIED LOVE that exists mutually between the FATHER and the SON..
      </p>

      <p>In the Old Testament, the Holy Spirit is the "breath of life."</p>

      <p>
        "Then the LORD God formed a man from the dust of the ground and breathed
        into his nostrils the breath of life, and the man became a living
        being." (Genesis 2:7)
      </p>

      <p>In the New Testament, The Holy Spirit takes a form of a "Dove"</p>

      <p>
        "As soon as Jesus was baptized, he went up out of the water. At that
        moment heaven was opened, and he saw the Spirit of God descending like a
        dove and alighting on him." (Mt. 3:16)
      </p>

      <p>In the form of a "Cloud"</p>

      <p>
        "He was still speaking when, behold, a bright cloud overshadowed them,
        and a voice from the cloud said, "This is my beloved Son, with whom I am
        well" (Mt 17:5)
      </p>

      <p>In the form of a "breath"</p>

      <p>
        "And when he had said this, he breathed on them and said to them,
        "Receive the Holy Spirit. WHOSE sins you forgive, they are forgiven
        them, whose sins you shall retain, they are retained." (Jn 20:22-23)
      </p>

      <p>In the form of "Tongues of Fire"</p>

      <p>
        Then there came a sound like the rush of a violent wind, and it filled
        the entire house where they were sitting. Divided tongues, as if fire
        appeared among them and a tongue rested on each of them. All of them
        were filled with the Holy Spirit and began to speak in other languages,
        as the spirit gave them the ability." (Acts 2: 1-4).
      </p>

      <p>
        THE HOLY SPIRIT IS THE LORD GIVER OF LIFE. He is the source of man’s
        health of the body and soul. All wellness of mind comes from Him. St.
        Paul has this to say, "Do you not know that your body is the Temple of
        The Holy Spirit within you which you have from God and that you are not
        your own? For you have been purchased at a great price (because your
        buyer is our Lord Jesus Christ). Therefore, glorify God in your body" (1
        Cor. 6:19).{" "}
      </p>

      <p>
        With REGARDS to the question on the origin of LIFE, many people had
        embraced the theory of evolution because the origin of Life cannot be
        properly explained.
      </p>

      <p>
        Modern science and the so called "modern scientists" may well explain
        the BIOLOGICAL aspect in man. They may be able to explain the physiology
        anatomy of human body at present. Modern medical scientists may very
        well explain the mechanism of the brain, the heart and all the parts of
        the human body. But these parts already existed as such at the instant
        in time of CREATION.
      </p>

      <p>
        In fact, medical science may well explain the conception and birth of a
        child and answer the questions on when and how life starts in the womb
        of a mother. But WHO has generated this life when Adam and Eve did not
        undergo the process of conception?
      </p>

      <p>
        SO, there is still that question on WHO has caused LIFE. Creating or
        making something out of NOTHING IS BEYOND the capacity of human hands or
        the production of a human mind. This is because explain a DIVINE ACT
        through a limited finite mind is not tenable. For as it is written in
        the Book of Genesis, "The Lord formed man out of clay of the ground..."
        and blew into his nostrils the breath of LIFE and so man became a living
        being. Modern Medical Science asserts the fact that Life starts at the
        instant of conception. But the FIRST MAN and the FIRST WOMAN (Adam and
        Eve) did NOT undergo the NORMAL process of conception. So, how did the
        first man and woman get their LIFE?
      </p>

      <p>
        Let us challenge any sculptor to form a statue of a man from clay and
        let him give life to this statue. We can even go further to challenge
        inventors of robots to give LIFE to what they have invented. Let those
        proponents of DNA assert from their findings on the ORIGIN OF MAN and
        let them pronounce the real origin of ADAM AND EVE. In our fourth
        reflection, we meditated on this fact: In man, spirit and matter form
        one nature. God creates every spiritual soul upon conception. This is
        why ABORTION is morally WRONG. The soul is not "produced" by the
        parents. It is infused by God at the time of conception.
      </p>

      <p>
        The Holy Spirit is the Lord and Giver of Life. HE is the source of
        STRENGTH, COURAGE and HEALTH to the Body and Soul in man.
      </p>

      <ReflectionPrayer day={day} />
    </>
  )
}

export default ReflectionDay6
