import React, { useContext } from "react"
import { GlobalDispatchContext } from "../../../context/global-context"

const OurFatherDialogButton = () => {
  const globalDispatch = useContext(GlobalDispatchContext)

  return (
    <button
      className="dialog-button"
      onClick={() => {
        globalDispatch({ type: "DIALOG_TOGGLED", key: "ourFather" })
      }}
    >
      Our Father
    </button>
  )
}

export default OurFatherDialogButton
