import React from "react"
import ReflectionPrayer from "../reflection-prayer"
import reflectionTitles from "../../../data/novena/reflections/titles.json"

const ReflectionDay2 = ({ day }) => {
  return (
    <>
      <h3 className="subheading">{reflectionTitles[day]}</h3>

      <p>
        Leader: In the Nicene Creed which is usually recited during the
        celebration of the Holy Mass, we find these words, "We believe in on
        God, the Father, the Almighty, maker of heaven and earth, of all that is
        seen and un-seen.". These words demonstrate the fact that there is a GOD
        whom we consider as a FATHER and ALMIGHTY. These same words are also
        found in the Apostle's Creed.
      </p>

      <p>How is this God known to man? Who revealed this GOD to us?</p>

      <p>
        Our GOD is a God of history. In the book of Exodus, our God identified
        himself to Moses as the God who guided the Patriarchs: Abraham, Isaac,
        and Jacob. This means that God revealed Himself to Moses. In fact, Moses
        asked, "If the people of Israel as me, 'What is his name?', what shall I
        say to them?". God said to Moses, "I AM WHO AM" and "Say to this people
        of Israel, 'O am HE who has sent you'" (Ex. 3:13-15). Although clearly
        revealed, His name shows that God is hidden yet always close to men. His
        name shows faithfulness because he was there in the past (the God of our
        fathers) and he will always be there. "I will be with you." (Ex.
        3:6-12).
      </p>

      <p>
        OUR GOD is a God that has NO BEGINNING AND NO END. Over the centuries,
        Israel realized that God had no end: "Your years have no end" (Ps
        102:26-27). James writes that in the Father, there is "no variation or
        shadow due to change." (1:17). "I am" means that God alone "is". He has
        no beginning and no end and from him all creatures receive their
        existence.
      </p>

      <p>
        OUR GOD is a God of MERCY. After Adam and Eve sinned, God promised to
        send a savior, a redeemer to save man from eternal damnation. He is a
        forgiving God, "a God merciful and gracious" (Ex. 34:6). His mercy is
        immeasurable, "...God who is rich in mercy, because of the great love He
        had for us, even when we were dead in our transgressions, brought us to
        life with Christ." (Eph. 2:4)
      </p>

      <p>
        Our GOD is a Faithful God. God's name "I Am" shows his faithfulness. In
        fact even after Israel sinned, God agreed to remain with them (Ex.
        32-33). Jesus had the same divine name too when before his Apostles He
        said, "When you have lifted up the Son of Man, then you will realize
        that I AM HE and that I do nothing of my own, but I speak these things
        as the Father instructed me." (John 8:28).
      </p>

      <p>
        We call our GOD, OUR FATHER because He causes the creation of the
        universe, and He is the source of everything that is seen and unseen.
        God the Father is the greatest artist and designer of all beings, of all
        things of all times. We must acknowledge His GREATNESS, with awe and
        respect.
      </p>

      <p>
        But in this modern world we live in, it is very sad and indeed very
        unfortunate to NOTICE AND SEE that people make their own gods. Some look
        at wealth as the fulfillment of their life. Their dream is to accumulate
        wealth, so they work hard every day.
      </p>

      <p>
        They do not have time anymore to worship and adore GOD. Others look at
        honor or prestige as their ultimate happiness on earth, still others
        attach themselves to the material things they own.
      </p>

      <p>
        This modern god of the modern man has no definite doctrines to follow.
        This god is subject to man's discretion and therefore subject to changes
        in the course of time.
      </p>

      <p>
        OUR GOD IS AN IMMUTABLE GOD. HE has specific doctrines that are well
        defined and easy to follow. OUR GOD has simple LAWS yet man has
        difficulty following these LAWS because he does not want to follow the
        WILL of this REAL GOD in heaven. Man wants to follow his own will, his
        own ways.
      </p>

      <p>
        So, man makes his own laws that are so complicated to follow. This is
        because man is self-centered and proud. Man tends to forget that there
        is a God, the Father of all.
      </p>

      <p>
        AS CATHOLICS, we pray the LORD'S PRAYER almost every day and we say,
        "Thy will be done on earth as it is in Heaven...".
      </p>

      <p>
        Are we, with all honesty, following the will of God? Or do we pray
        without meaning what we say. If we don't mean what we say, then, how
        will God answer our prayers?
      </p>

      <ReflectionPrayer day={day} />
    </>
  )
}

export default ReflectionDay2
