import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const ReflectionsMenu = ({ day }) => {
  const data = useStaticQuery(graphql`
    query NovenaPrayerMenuData {
      allSitePage(filter: { path: { regex: "/^/novena-prayers/day/i" } }) {
        nodes {
          path
          context {
            day
          }
        }
      }
    }
  `)

  return (
    <div className="nav-wrapper">
      <div className="nav-container">
        <ul className="reflection-days">
          {data.allSitePage.nodes.map(p => {
            const className = p.context.day === day ? "active" : ""
            return (
              <Link key={p.context.day} to={p.path} className={className}>
                Day {p.context.day}
              </Link>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default ReflectionsMenu
