import React from "react"

const LitanyEntranceSong = () => {
  return (
    <div className="litany-entrance-song">
      <h3 className="subheading">Entrance Song</h3>

      <p>Come Holy Ghost, Creator Blest</p>
      <p>And in our souls, take up your rest</p>
      <p>Come with your grace and heavenly aid</p>
      <p>To fill the hearts which you have made</p>
      <p>To fill the hearts which you have made</p>
    </div>
  )
}

export default LitanyEntranceSong
