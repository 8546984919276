import React from "react"

const DialogContainer = ({ data, className }) => {
  className = className || "dialog-container"

  return (
    <div className={`${className}`}>
      {data.map((s, i) => {
        return (
          <div className="row" key={i}>
            <div className="column">{s.speaker}</div>
            <div
              className="column response"
              dangerouslySetInnerHTML={{ __html: s.response }}
            ></div>
          </div>
        )
      })}
    </div>
  )
}

export default DialogContainer
