import React from "react"
import PostReflectionPrayersPetitionData from "../../data/novena/shared/post-reflection-prayers-petitions.json"
import DialogContainer from "./dialog-container"

const PostReflectionPrayersPetition = () => {
  return (
    <>
      <h3 className="subheading">Petitions to the Blessed Trinity</h3>
      <DialogContainer data={PostReflectionPrayersPetitionData} />
    </>
  )
}

export default PostReflectionPrayersPetition
