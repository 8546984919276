import React from "react"

const LitanyActOfContrition = () => {
  return (
    <div className="litany-act-of-contrition">
      <h3 className="subheading">The Act of Contrition</h3>

      <p>
        Oh my God, I am heartily sorry for having offended Thee and I detest all
        my sins because I dread the loss of heaven and I am afraid of the pains
        of Hell. But most of all, I have offended Thee my God, who art all good
        and deserving of all my love. I firmly resolve with the help of Thy
        grace to confess my sins, to do my penance, to amend my life and sin no
        more. AMEN.
      </p>
    </div>
  )
}

export default LitanyActOfContrition
