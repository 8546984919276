import React from "react"

const RosaryDoxology = () => {
  return (
    <>
      <h3 className="subheading">The Doxology</h3>
      <p>
        In the name of the Father and of the Son and of the Holy Spirit. AMEN.
      </p>
    </>
  )
}

export default RosaryDoxology
