import React from "react"
import DialogContainer from "./dialog-container"
import HailHolyQueenDialogData from "../../data/novena/shared/rosary-hail-holy-queen.json"

const RosaryHailHolyQueen = () => {
  return (
    <>
      <h3 className="subheading">Hail Holy Queen</h3>
      <p>
        Hail Holy Queen, Mother of Mercy. Hail, our life, our sweetness and our
        hope. To thee, do we cry, poor banish children of Eve. To thee do we
        send up our sighs, mourning and weeping in this valley of tears. Turn
        then, Oh most gracious Advocate thine eyes of mercy toward us. And after
        this, our exile, show us the Blessed fruit of thy womb, Jesus. Oh
        Clement, Oh loving, Oh Sweet Virgin Mary. Amen.
      </p>

      <DialogContainer data={HailHolyQueenDialogData} />
    </>
  )
}

export default RosaryHailHolyQueen
