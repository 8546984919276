import React from "react"
import ReflectionPrayer from "../reflection-prayer"
import reflectionTitles from "../../../data/novena/reflections/titles.json"

const ReflectionDay1 = ({ day }) => {
  return (
    <>
      <h3 className="subheading">{reflectionTitles[day]}</h3>

      <p>
        Leader: Pope Leo XII, in his Encyclical on the Holy Spirit, defines the
        Blessed Trinity as the greatest mysteries of the Catholic Church because
        it is the "substance of the New Testament".
      </p>

      <p>
        In baptism, the fundamental truth and reality that one is baptized "in
        the name of the Father, and of the Son and of the Holy Spirit" must be
        accepted and believed. Becoming a Christian necessitates that a person
        must have to submit oneself to Baptism, an act that even Christ
        submitted Himself into.
      </p>

      <p>
        The Trinity has always been the very root of the Church's faith
        expressed in the baptismal liturgy. Even in the opening of the Liturgy
        of the Eucharist when the priest says, "The grace of the Lord Jesus
        Christ, the love of God and the fellowship of the Holy Spirit be with
        you all" (2 Cor. 13:13), the BLESSED TRINITY is already invoked.
      </p>

      <p>
        When the Church speaks of the Blessed Trinity, there are THREE TERMS
        that we must put into our minds in order to give us a better
        understanding of the Blessed Trinity. These are:
      </p>

      <div>
        <ol>
          <li>
            SUBSTANCE, also called ESSENCE or NATURE. This affirms that the
            Blessed Trinity is of ONE essence and ONE nature.
          </li>
          <li>PERSON, which clearly designates Father, Son and Holy Spirit.</li>
          <li>
            RELATION, which designates that their distinction lies in their
            relationship with each other. (Catechism of the Church)
          </li>
        </ol>
      </div>

      <p>
        <u>The Blessed Trinity is ONE.</u> The Father, the Son, the Holy Spirit
        have the same essence or nature which is DIVINE. The Church does NOT
        teach that in the Blessed Trinity they are distinct from one another.
        But each of them is GOD, whole and entire. Even when we make the sign of
        the cross, we say with great faith and reverence, "In the NAME of the
        Father, and of the Son, and of the Holy Spirit". We do not say "In the
        NAMES of the Father, the Son, and Holy Spirit" because the three persons
        do not share the one divinity among themselves. Each of the persons is
        that supreme reality of the divine substance, divine essence or divine
        nature.
      </p>

      <p>
        The Blessed Trinity is really distinct from one another in their
        RELATIONS of origin. As stated in the fourth Lateran Council, "It is the
        Father who generates the Son who is the begotten, consubstantial with
        HIM and with the HOLY SPIRIT, who proceeds from the Father and the Son"
        (Fourth Lateran Council). They work together harmoniously. There is NO
        power struggle between them.
      </p>

      <p>
        Let us not wonder, or think or ask: "How can there be three Persons in
        only One God?". Asking this question will just lead us to be confused.
        And confusion can lead to doubts in what we believe. This is because the
        human mind is a limited faculty in man. The human mind fails to
        understand what FAITH can see with clarity. Believing and seeing at the
        same time do not hold through at all times.
      </p>

      <p>
        Instead why don't we put what we believe into action and good deeds?
      </p>

      <p>Let us meditate on these words of SAINT JAMES:</p>

      <p>
        <sup>14</sup> What good is it, my brothers and sisters, if someone
        claims to have faith but has no deeds? Can such faith save him?
        <br />
        <sup>15</sup> Suppose a brother or a sister is without clothes and daily
        food.
        <br />
        <sup>16</sup> If one of you says to them, "Go in peace; keep warm and
        well fed." but does nothing about their physical needs, what good is it?
        <br />
        <sup>17</sup> In the same way, faith by itself, if it is not accompanied
        by action, is dead.
        <br />
        <sup>18</sup> But someone will say, "You have faith; I have deeds. Show
        me your faith without deeds, and I will show you my faith by my deeds.
        <br />
        <sup>19</sup> You believe that there is one God. Good! Even the demons
        believe that; and they tremble..."
        <br />
        <sup>20</sup> You foolish person, do you want evidence that faith
        without deeds is useless?
        <br />
        <sup>21</sup> Was not our father Abraham considered righteous for what
        he did when he offered his son Isaac on the altar?
        <br />
        <sup>22</sup> You see that his faith and his actions were working
        together, and his faith was made complete by what he did.
        <br />
        <sup>23</sup> And the scripture was fulfilled that says, "Abraham
        believed God, and it was credited to him as righteousness." and he was
        called God's friend.
        <br />
        <sup>24</sup> You see that a person is considered righteous by what they
        do and not by faith alone.
      </p>

      <p>(St. James 2:14-24)</p>

      <ReflectionPrayer day={day} />
    </>
  )
}

export default ReflectionDay1
