import React from "react"
import OpeningPrayersPraiseData1 from "../../data/novena/shared/opening-prayers-praises-1.json"
import OpeningPrayersPraiseData2 from "../../data/novena/shared/opening-prayers-praises-2.json"
import DialogContainer from "./dialog-container"
import Divider from "./divider"
import OurFatherDialogButton from "./dialog-boxes/our-father-dialog-button"
import HailMaryDialogButton from "./dialog-boxes/hail-mary-dialog-button"
import GloryBeDialogButton from "./dialog-boxes/glory-be-dialog-button"

const OpeningPrayersPraises = () => {
  return (
    <div className="opening-prayers-container">
      <h3 className="subheading">Praises to the most Blessed Trinity</h3>

      <div className="highlight">
        The "Praises" below must be recited and repeated 10 times. After each
        "Praise", pray one <OurFatherDialogButton />
        , one <HailMaryDialogButton />
        , and one <GloryBeDialogButton />
      </div>

      <DialogContainer data={OpeningPrayersPraiseData1} />
      <Divider />
      <DialogContainer data={OpeningPrayersPraiseData2} />

      <p>
        <em>Prayers continued after Reflection...</em>
      </p>
    </div>
  )
}

export default OpeningPrayersPraises
