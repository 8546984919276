import React from "react"
import OurFatherDialogButton from "./dialog-boxes/our-father-dialog-button"
import HailMaryDialogButton from "./dialog-boxes/hail-mary-dialog-button"
import GloryBeDialogButton from "./dialog-boxes/glory-be-dialog-button"

const RosaryMysteries = () => {
  return (
    <>
      <div className="highlight mystery">
        After reciting each mystery, say one <OurFatherDialogButton />, 10{" "}
        <HailMaryDialogButton />, and one <GloryBeDialogButton />
      </div>

      <h3 className="subheading">
        The Joyful Mysteries -- Mondays &amp; Saturdays
      </h3>
      <ul>
        <li>The Annunciation</li>
        <li>The Visitation</li>
        <li>The Birth of the Lord</li>
        <li>The Presentation</li>
        <li>The Finding of Jesus in the Temple</li>
      </ul>

      <h3 className="subheading">
        The Sorrowful Mysteries -- Tuesdays &amp; Fridays
      </h3>
      <ul>
        <li>The Agony in the Garden of Gethsemane</li>
        <li>The Scourging at the Pillar</li>
        <li>The Crowning with Thorns</li>
        <li>The Carrying of the Cross</li>
        <li>The Crucifixion and Death of the Lord</li>
      </ul>

      <h3 className="subheading">The Luminous Mysteries -- Thursdays</h3>
      <ul>
        <li>The Baptism of Jesus</li>
        <li>Christ's Manifestation at the Wedding in Cana</li>
        <li>The Proclamation of the Kingdom of God</li>
        <li>The Transfiguration</li>
        <li>The Institution of the Holy Eucharist</li>
      </ul>

      <h3 className="subheading">
        The Glorious Mysteries -- Wednesdays &amp; Sundays
      </h3>
      <ul>
        <li>The Resurrection</li>
        <li>The Ascension</li>
        <li>The Descent of the Holy Spirit</li>
        <li>The Assumption of Mother Mary</li>
        <li>The Coronation of Mother Mary as Queen</li>
      </ul>
    </>
  )
}

export default RosaryMysteries
