import React from "react"
import DialogContainer from "./dialog-container"

const ReflectionPrayer = ({ day }) => {
  const data = require(`../../data/novena/reflections/day-${day}-prayer.json`)

  return (
    <>
      <h3 className="subheading">Prayer</h3>
      <DialogContainer data={data} />
    </>
  )
}

export default ReflectionPrayer
